import { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import { motion } from "framer-motion";
import Gallery from "../../components/gallery";
import Border from "../../components/borders/border";

const Home: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [isVisible, setIsVisible] = useState(true);

  const handleAnimationComplete = () => {
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dotVariants = {
    hidden: { opacity: 0, zIndex: -1, ease: "linear" },
    visible: { opacity: 1, zIndex: 1, ease: "linear" },
  };
  const circles = [
    { cx: 137.1, cy: 137.1 },
    { cx: 411.4, cy: 137.1 },
    { cx: 685.7, cy: 137.1 },
    { cx: 960, cy: 137.1 },
    { cx: 1234.3, cy: 137.1 },
    { cx: 1508.6, cy: 137.1 },
    { cx: 1782.9, cy: 137.1 },
    { cx: 137.1, cy: 411.4 },
    { cx: 411.4, cy: 411.4 },
    { cx: 685.7, cy: 411.4 },
    { cx: 960, cy: 411.4 },
    { cx: 1234.3, cy: 411.4 },
    { cx: 1508.6, cy: 411.4 },
    { cx: 1782.9, cy: 411.4 },
    { cx: 137.1, cy: 685.7 },
    { cx: 411.4, cy: 685.7 },
    { cx: 685.7, cy: 685.7 },
    { cx: 960, cy: 685.7 },
    { cx: 1234.3, cy: 685.7 },
    { cx: 1508.6, cy: 685.7 },
    { cx: 1782.9, cy: 685.7 },
    { cx: 137.1, cy: 960 },
    { cx: 411.4, cy: 960 },
    { cx: 685.7, cy: 960 },
    { cx: 960, cy: 960 },
    { cx: 1234.3, cy: 960 },
    { cx: 1508.6, cy: 960 },
    { cx: 1782.9, cy: 960 },
  ];
  const [dotsVisible, setDotsVisible] = useState(true);
  const [fadeOutStart, setFadeOutStart] = useState(false);
  const [fadeOutStates, setFadeOutStates] = useState(
    new Array(circles.length).fill(false)
  );
  const generateRandomDelay = (maxDelay: number) => Math.random() * maxDelay;

  useEffect(() => {
    circles.forEach((_, index) => {
      const fadeOutDelay = 4 + generateRandomDelay(2);
      setTimeout(() => {
        setFadeOutStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = true;
          return newStates;
        });
      }, fadeOutDelay * 1000);
    });
  }, []);

  const variants = {
    animate: (custom: { delay: any }) => ({
      r: "137.1",
      opacity: 1,
      transition: { delay: custom.delay, duration: 1 },
    }),
    fadeOut: (custom: { fadeOutDelay: any }) => ({
      r: "0,0",
      transition: { delay: custom.fadeOutDelay, duration: 1 },
    }),
  };
  return (
    <>
      {/* <div className='header'>
                <p>
                    15,988 SQ FT <br></br> CAT-A OFFICE SPACE
                    <br></br>
                    <br></br>
                    SE1 2YU
                </p>
            </div> */}
      <motion.svg
        initial={{}}
        animate={{ display: "none" }}
        transition={{ delay: isMobile ? 0 : 7 }}
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 0 1920 1097.1"
      >
        {circles.map((circle, index) => {
          const delay = generateRandomDelay(2);
          const fadeOutDelay = 6 + generateRandomDelay(6);

          return (
            <motion.circle
              key={index}
              custom={{ delay, fadeOutDelay }}
              initial={{ opacity: 0 }}
              animate={fadeOutStates[index] ? "fadeOut" : "animate"}
              transition={{
                transitionEnd: {
                  display: "none",
                },
              }}
              variants={variants}
              cx={circle.cx}
              cy={circle.cy}
              r={"137.1"}
            />
          );
        })}
      </motion.svg>
      <div className="animations">
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "100%" }}
          transition={{ delay: isMobile ? 0.5 : 3, duration: 1 }}
          className="seperator"
        />
        <motion.div
          className="logo"
          initial={{ left: "calc(50% - 521px)", top: "50%" }}
          animate={{
            left: isMobile ? "1rem" : "calc(50% - 137px)",
            top: isMobile ? "1rem" : "0",
          }}
          transition={{ duration: 1, delay: isMobile ? 0 : 7 }}
        >
          <div className="logo-images">

          
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: isMobile ? 1 : 3, duration: 1 }}
          >
            <motion.img
              initial={{ width: "500px",  }}
              animate={{
                width: isMobile ? "19vw" : "125px",
              }}
              transition={{
                duration: 1,
                delay: isMobile ? 0 : 7,
              }}
              src="../images/conran.svg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: isMobile ? 1 : 3 }}
          >
            <motion.img
              className="shad"
              initial={{ width: "200px", paddingLeft: '15px' }}
              animate={{
                width: isMobile ? "40px" : "50px", paddingLeft: '0'
              }}
              transition={{
                duration: 1,
                delay: isMobile ? 0 : 7,
              }}
              src="../images/shad.svg"
            />
          </motion.div>
          </div>
        </motion.div>

        <motion.div
          initial={isMobile ? {} : { opacity: 0, y: 50 }}
          animate={isMobile ? { height: 0 } : { opacity: 1, y: 0 }}
          transition={{
            delay: isMobile ? 6 : 9,
            duration: isMobile ? 1.25 : 0.5,
          }}
          className="images-wrapper"
        >
          <motion.div
            animate={{ display: "none" }}
            transition={{ delay: isMobile ? 6.5 : 17 }}
            className="image-wrapper"
          >
            <motion.div
              initial={{ opacity: 1 }}
              animate={isMobile ? { opacity: 0 } : { x: "-100%" }}
              transition={{
                delay: isMobile ? 5 : 15,
                duration: 1,
              }}
              onAnimationComplete={() => {}}
              style={{
                backgroundImage: "url('../images/building.jpg')",
              }}
            >
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: isMobile ? 3 : 10,
                  duration: 0.5,
                }}
              >
                DESIGN
              </motion.h1>
            </motion.div>
          </motion.div>
          <motion.div
            animate={{ display: "none" }}
            transition={{ delay: isMobile ? 6.5 : 17 }}
            className="image-wrapper"
          >
            <motion.div
              animate={isMobile ? { opacity: 0 } : { x: "100%" }}
              transition={{
                delay: isMobile ? 5 : 15,
                duration: 1,
              }}
              style={{
                backgroundImage: "url('../images/portrait.jpg')",
              }}
              onAnimationComplete={() => {}}
            >
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: isMobile ? 3.5 : 11,
                  duration: 0.5,
                }}
              >
                ICON
              </motion.h1>
            </motion.div>
          </motion.div>
          <div></div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0, delay: isMobile ? 6 : 15 }}
          className="gallery-wrapper"
        >
          <Gallery
            slides={[
              {
                src: "../video/1.mp4",
                caption: " ",
                video: true,
              },
              {
                src: "../images/cgis/1.jpg",
                caption: "FIRST FLOOR OFFICE SPACE",
              },
              {
                src: "../images/cgis/2.jpg",
                caption: "MEETING ROOMS",
              },
              {
                src: "../images/cgis/3.jpg",
                caption: "FIRST FLOOR OFFICE SPACE",
              },
              {
                src: "../images/cgis/4.jpg",
                caption: "FOURTH & FIFTH FLOORS",
              },
              {
                src: "../images/cgis/7.jpg",
                caption: "FOURTH & FIFTH FLOORS",
              },
              {
                src: "../images/cgis/5.jpg",
                caption: "FIFTH FLOOR - VIEW ONTO THE TERRACE",
              },
              {
                src: "../images/cgis/6.jpg",
                caption: "FIFTH FLOOR ROOF TERRACE",
              },
            ]}
          />
        </motion.div>
      </div>
      <div className="content">
        <div className="hdr">
          <h4>AVAILABLE FROM <br/> September 2024</h4>
        </div>
        <div className="right-copy">
          <div className="copy">
            <motion.p
              initial={{ opacity: 0, y: "25%" }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
              viewport={{ once: true }}
            >
              Become a custodian of quality and take space in a home that has
              made british design history.
              <br></br>
              <br></br>
              15,988 sq ft of high quality, fully restored and highly
              sustainable, Thameside office space in London’s newest listed
              building. Built by Sterling-prize winner Hopkins for David Mellor
              CBE, re-imagined by Sir Terence Conran, now updated and restored
              to design-led, contemporary office space by Squire & Partners.
            </motion.p>
          </div>
          <motion.div
            initial={{ opacity: 0, y: "25%" }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
            viewport={{ once: true }}
            className="stacked"
          >
            <p>15,988 SQ FT</p>
            <Border color={"#2B3333"} top={true} />
            <p>6 FLOORS WITH THAMESIDE VIEWS</p>
            <Border color={"#2B3333"} top={true} />
            <p>EXCEPTIONAL VIEWS OF TOWER BRIDGE, THE SHARD AND BEYOND</p>
            <Border color={"#2B3333"} top={true} />
            <p>HIGHLY SUSTAINABLE, DESIGN-LED OFFICE SPACE</p>
            <Border color={"#2B3333"} top={true} />
            <p>FULLY FITTED FLOORS, READY TO MOVE IN</p>
            <Border color={"#2B3333"} top={true} />
            <p>DESIGN AND LAYOUT OPTIONS AVAILABLE FOR CAT A FLOORS</p>
            <Border color={"#2B3333"} top={true} />
            <p>MANAGED OFFICE SOLUTION OPTION PROVIDED BY KITT</p>
            <Border color={"#2B3333"} top={true} />

            <a className="download-btn" href="../dl/brochure.pdf" target="_blank">
              <p>DOWNLOAD BROCHURE</p>
              <img
                src="../images/greenarrow.svg"
                className="arrow"
                alt="arrow"
              />
            </a>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: "25%" }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.2 }}
        className="flex-center"
      >
        <h1>BETTER</h1>
      </motion.div>
      <div className="footer">
        <motion.div
          initial={{ opacity: 0, y: "25%" }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
          viewport={{ once: true }}
          className="green"
        >
          <div className="top">
            <div className="right">
              <h1 className="mobile-design">DESIGN</h1>
              <h3>GET IN TOUCH</h3>
              <div className="contact-wrapper">
                <div className="contact">
                  <img alt="logo" src="../images/bfs.svg" />
                  <div className="agent">
                    <p>Rob Skioldebrand</p>
                    <a href="mailto:robertskioldebrand@brayfoxsmith.com">
                      <p>robertskioldebrand@brayfoxsmith.com</p>
                    </a>
                    <p>+44 (0)7769 725 412</p>
                  </div>
                  <div className="agent">
                    <p>Jake Stace</p>
                    <a href="mailto:jakestace@brayfoxsmith.com">
                      <p>jakestace@brayfoxsmith.com</p>
                    </a>
                    <p>+44 (0)7597 685 889</p>
                  </div>
                </div>
                <div className="contact">
                  <img alt="logo" src="../images/colliers.svg" />
                  <div className="agent">
                    <p>Charlie Collins</p>
                    <a href="mailto:charlie.collins@colliers.com">
                      <p>charlie.collins@colliers.com</p>
                    </a>
                    <p>+44 (0)7759 121 247</p>
                  </div>
                  <div className="agent">
                    <p>Joseph Mishon</p>
                    <a href="mailto:joseph.mishon@colliers.com">
                      <p>joseph.mishon@colliers.com</p>
                    </a>
                    <p>+44 (0)7876 724 488</p>
                  </div>
                </div>
              </div>
              <div className="kit-container">
                {" "}
                <p>Managed Office solution option provided by:</p>
                <div className="kit-wrapper">
                  <div className="kitt">
                    <img src="../images/Kitt_Logo_Pale.png" alt="kitt" />
                  </div>
                  <div className="agent">
                    <p>Poppy Barker</p>
                    <a href="mailto:poppy@kittoffices.com">
                      <p>poppy.barker@kittoffices.com</p>
                    </a>
                    <p>+44 (0)7878 859 429</p>
                  </div>
                  <div className="agent">
                    <p>Ben Danaher</p>
                    <a href="mailto:ben.danaher@kittoffices.com">
                      <p>ben.danaher@kittoffices.com</p>
                    </a>
                    <p>+44 (0)7896 783 568</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="left">
              <div className="seperator">
                <h3>A DEVELOPMENT BY</h3>
                <div className="development">
                  <div className="logo-title">
                    <h4>Development / Funding partner: </h4>
                    <img
                      className="amazon"
                      src="../images/amazon.svg"
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="development">
                  <div className="logo-title">
                    <h4>Development Manager:</h4>
                    <img src="../images/lateral.svg" alt="logo" />
                  </div>
                </div>
                <div className="development">
                  <div className="logo-title">
                    <h4>Architect</h4>
                    <img src="../images/s&p.svg" alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bot">
            <h1 className="design">DESIGN</h1>
          
          <div className="misrep">
            <p>
              Misrepresentation Act: The joint agents, for themselves and for
              the vendors or lessors of this property, whose agents they are,
              give notice that: These particulars are produced in good faith,
              but are set out as a general guide only, and do not constitute any
              part of a contract; No person in the employment of the joint
              agents has any authority to make or give any representation or
              warranty whatever in relation to this property; Unless otherwise
              stated all rents or prices quoted are exclusive of VAT which may
              be payable in addition. October 2024.
              <br></br>
              <br></br>
              siren | +44 (0)20 7478 4300 | sirendesign.co.uk
            </p>
          </div>
          <div className="address">
            <p>CONRAN BUILDING, 22 SHAD THAMES, SE1 2YU</p>
          </div></div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: "25%" }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          className="by"
        >
          <h1>BY</h1><div className="add">
          <p>
          CONRAN BUILDING<br></br>22 SHAD THAMES<br></br>SE1 2YU
          </p>
        </div>
        </motion.div>
        
      </div>
    </>
  );
};

export default Home;
