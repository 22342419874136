import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper/modules";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import "swiper/css";
import "./gallery.scss";

interface GalleryProps {
    slides: { src: string; caption: string; video? : boolean }[];
}

const Gallery: React.FC<GalleryProps> = ({ slides }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(1);


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
    }, []);

    const handleSlideIncrement = () => {
        console.log("slide changed");
        if (currentSlide === slides.length) {
            setCurrentSlide(1);
        } else {
            setCurrentSlide((prevSlide) => prevSlide + 1);
        }
    };
    const handleSlideDecrement = () => {
        console.log("slide changed");
        if (currentSlide === 1) {
            setCurrentSlide(6);
        } else {
            setCurrentSlide((prevSlide) => prevSlide - 1);
        }
    };

    const toggleVideo = () => {
        const videoElement = document.getElementById("video") as HTMLVideoElement;
        const playButton = document.getElementById("play");
        if (videoElement.paused) {
            videoElement.play();
            if (playButton) {
                playButton.style.display = "none";
            }
        } else {
            videoElement.pause();
            if (playButton) {
                playButton.style.display = "block";
            }
        }
    };

    return (
        <>
            <SwiperComponent
                id={"swiper"}
                modules={[Navigation, Autoplay, EffectFade]}
                loop={true}
                initialSlide={0}
                centeredSlides={true}
                slidesPerView={1}
                // autoplay={{ delay: 3000 }}
                effect={'fade'}
                onSlideNextTransitionStart={handleSlideIncrement}
                onSlidePrevTransitionStart={handleSlideDecrement}
                navigation={{
                    nextEl: ".arrow-next",
                    prevEl: ".arrow-prev",
                }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        {slide.video ? (
                            <div className="video-wrapper">
                            <div onClick={toggleVideo} id="play" className="play">
                                <img
                                    src='../images/playbutton.svg'                                    
                                    alt='play'
                                />
                            </div>
                            <video
                                src={'https://player.vimeo.com/progressive_redirect/playback/1015196094/rendition/720p/file.mp4?loc=external&signature=de97ac857e959b0bac3f2843ce92cf2e76a9f191f7f9298de213a2369601a3c5'}
                                height={'100%'}
                                loop
                                controls
                                className='swiper-video'
                                poster="../images/videoPoster.jpg"
                                id="video"
                            />
                            {/* <div style={{padding:'56.25% 0 0 0',position: 'relative'}}><iframe src="https://player.vimeo.com/video/1015196094?h=e5363c8767&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="Conran film 04"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
                            </div>
                        ) : (
                            <div className="swiper-img-back" style={{ backgroundImage: `url(${slide.src})`}} />
                        )}
                    </SwiperSlide>
                ))}
                <motion.div
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    transition={{ delay: isMobile ? 6 : 16, ease: "easeOut"}}
                    className='nav-wrapper'
                >
                    <div>
                        <p>{slides[currentSlide - 1].caption}</p>
                    </div>
                    <div>
                        <img
                            src='../images/arrow.svg'
                            className='arrow-prev'
                            alt='arrow'
                        />{" "}
                        <p>{currentSlide}/{slides.length}</p>
                        <img
                            src='../images/arrow.svg'
                            className='arrow-next'
                            alt='arrow'
                        />
                    </div>
                </motion.div>
            </SwiperComponent>
        </>
    );
};

export default Gallery;
